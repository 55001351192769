/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";

const Map = (props) => {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className="container contact-page-map container-map">
        <h2 className="text-center">We Cover {rpdata?.dbPrincipal?.miles} Miles Around {rpdata?.dbPrincipal?.location?.[0].address}</h2>
        <div className="row">
          <div className="col-lg-12 col-md-6 text-center content-directorios">
            <iframe src={rpdata?.dbPrincipal?.location?.[0].url} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Map;
